<div class="modal-header text-center">
    <h4 class="modal-title text-center">Scratch Beginners Registration</h4>
    <button type="button" class="btn-close" aria-label="Close" (click)="close()"></button>
</div>
<div class="modal-body">
    <div class="col-md-12" *ngIf="!registrationCompleted">
        <form novalidate [formGroup]="form">
            <div class="row">
                <div class="form-group col-md-12">
                    <label for="gender">Parent Full Name</label>
                    <input type="text" class="form-control" id="parentName" formControlName="parentName" maxlength="200" placeholder="Enter Parent Full Name" [ngClass]="{'is-invalid': submit && !parentName.valid}">
                    <div *ngIf="submit && !parentName.valid" class="invalid-feedback">
                        <span *ngIf="parentName.errors.required">Required.</span>
                    </div>
                </div>
                <div class="form-group col-md-12 pt-20">
                    <label for="gender">Student Full Name</label>
                    <input type="text" class="form-control" id="studentName" formControlName="studentName" maxlength="200" placeholder="Enter Student Full Name" [ngClass]="{'is-invalid': submit && !studentName.valid}">
                    <div *ngIf="submit && !studentName.valid" class="invalid-feedback">
                        <span *ngIf="studentName.errors.required">Required.</span>
                    </div>
                </div>
                <div class="form-group col-md-12 pt-20">
                    <label for="name">Student Age</label>

                    <select class="form-control" formControlName="studentAge" id="studentAge" [ngClass]="{'is-invalid': submit && !studentAge.valid}">
                        <option value=""> - Select - </option>
                        <option value="8">8</option>
                        <option value="9">9</option>
                        <option value="10">10</option>
                        <option value="11">11</option>
                        <option value="12">12</option>
                        <option value="13">13</option>
                        <option value="14">14</option>
                    </select>

                    <div *ngIf="submit && !studentAge.valid" class="invalid-feedback">
                        <span *ngIf="studentAge.errors.required">Required.</span>
                    </div>
                </div>
                <div class="form-group col-md-12 pt-20">
                    <label for="gender">Contact Email</label>
                    <input type="text" class="form-control" id="email" formControlName="email" maxlength="200" placeholder="Enter Contact Email" [ngClass]="{'is-invalid': submit && !email.valid}">
                    <div *ngIf="submit && !email.valid" class="invalid-feedback">
                        <span *ngIf="email.errors.required">Required.</span>
                        <span *ngIf="email.errors.email">Invalid email format.</span>
                    </div>
                </div>
                <div class="form-group col-md-12 pt-20">
                    <label for="phoneNumber">Contact Phone</label>
                    <input type="text" class="form-control" id="phoneNumber" formControlName="phoneNumber" mask="000-000-0000" placeholder="xxx-xxx-xxxx" [ngClass]="{'is-invalid': submit && !phoneNumber.valid}">
                    <div *ngIf="submit && !phoneNumber.valid" class="invalid-feedback">
                        <span *ngIf="phoneNumber.errors.required">Required.</span>
                    </div>
                </div>
            </div>

            
        </form>
    </div>
    <div class="col-md-12 pt-20" *ngIf="studentAlreadyRegistered">
        <div class ="alert alert-danger">
            Student is already registered
        </div>
    </div>

    <div class="col-md-12 pt-20" *ngIf="errorRegistering">
        <div class ="alert alert-danger">
            Error registering student. please try again!
        </div>
    </div>

    <div class="col-md-12 pt-20" *ngIf="!errorRegistering && registrationCompleted">
        <div class ="alert alert-success">
            <h2 class="text-center">Registration Completed!</h2>
            <p style="text-align: justify; margin-top: 10px;">
                Thank you for registering your child for the <b>Scratch Beginners</b> course at Createch Academy. <br/><br/>
                A confirmation email with course details will be sent shortly. If you have any questions, feel free to contact us. <br/><br/>
                We look forward to seeing your child in class!
            </p>
        </div>
    </div>
</div>
<div class="modal-footer" *ngIf="!registrationCompleted">
    <button type="button" class="default-btn" (click)="register()" [disabled]="registering"><i class="flaticon-rocket"></i> Register</button>
</div>