<footer class="{{footerClass}}">
    <div class="container">
        <div class="footer-bottom-area">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <img src="assets/img/CreatechAcademyLogo2.png" style="max-height: 70px;" >
                </div>
                <div class="col-lg-6 col-md-6">
                    <p style="text-align: right;">© Createch Academy Owned by <b>Createch Solutions INC</b></p>
                </div>
            </div>
        </div>
    </div>
</footer>

<div class="go-top"><i class="flaticon-up"></i></div>