import { Component, inject, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CourseRegistrationComponent } from '../course-registration/course-registration.component';

@Component({
  selector: 'app-scratch-beginners',
  templateUrl: './scratch-beginners.component.html',
  styleUrl: './scratch-beginners.component.scss'
})
export class ScratchBeginnersComponent {
  
	private modalService = inject(NgbModal);

  ngOnInit(): void {
  }

  public openRegistration(): void {
    const modalRef = this.modalService.open(CourseRegistrationComponent);
  }

  mainBannerContent: Content[] = [
      {
          defaultBtnIcon: 'flaticon-rocket',
          defaultBtnText: 'About Us',
          defaultBtnLink: 'about-us',
          videoBtnIcon: 'flaticon-google-play',
          videoBtnText: 'Watch Video',
          videoBtnLink: 'https://www.youtube.com/watch?v=spqBGurE3fU'
      }
  ]

}

class Content {
  defaultBtnIcon : string;
  defaultBtnText: string;
  defaultBtnLink : string;
  videoBtnIcon : string;
  videoBtnText: string;
  videoBtnLink : string;
}
