import { Component} from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormBuilder, Validators, AbstractControl } from '@angular/forms';
import { AngularFireDatabase, AngularFireObject } from '@angular/fire/compat/database';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/compat/firestore';
import { map } from 'rxjs';

@Component({
  selector: 'app-course-registration',
  templateUrl: './course-registration.component.html',
  styleUrl: './course-registration.component.scss'
})
export class CourseRegistrationComponent {

  public form: FormGroup;
  public submit: boolean = false;
  public studentAlreadyRegistered: boolean = false;
  public discountInRegistration: boolean = false;
  public registrationCompleted: boolean = false;
  public errorRegistering: boolean = false;
  public registering: boolean = false;

  private scratchBeginnersCollection: AngularFirestoreCollection<CourseRegistration>

  registrations: CourseRegistration[];

  constructor(public activeModal: NgbActiveModal
    , private _formBuilder: FormBuilder
    , private firestore: AngularFirestore) { 

      this.scratchBeginnersCollection = this.firestore.collection<CourseRegistration>('scratchBeginners');
      this.subscribeToRegistraitons();
      this.buildForm();
    }

   //#region FormControls
   get parentName(): AbstractControl {
    return this.form.get('parentName');
  }

  get studentName(): AbstractControl {
    return this.form.get('studentName');
  }

  get studentAge(): AbstractControl {
    return this.form.get('studentAge');
  }

  get email(): AbstractControl {
    return this.form.get('email');
  }

  get phoneNumber(): AbstractControl {
    return this.form.get('phoneNumber');
  }


  public close(): void{
    this.activeModal.close();
  }

  private buildForm(): void {

    this.form = this._formBuilder.group({
      parentName: ['', [Validators.required]],
      studentName:['', [Validators.required]],
      studentAge:['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: ['', [Validators.required]],
    });
  }

  private subscribeToRegistraitons(): void{
    this.scratchBeginnersCollection.snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({ id: c.payload.doc.id, ...c.payload.doc.data() })
        )
      )
    ).subscribe(data => {
      this.registrations = data;
    });
  }

  public register():void{
    this.submit = true;
    this.errorRegistering = false;
    this.registering = true;

    if(this.form.valid){

      let registration = new CourseRegistration();
      registration.parentName = this.parentName.value;
      registration.studentName = this.studentName.value;
      registration.studentAge = +this.studentAge.value;
      registration.email = this.email.value.toLowerCase();
      registration.phoneNumber = this.phoneNumber.value;
      registration.applyDiscount = false;
      registration.createdOn = new Date();

      if(this.registrations && this.registrations.length < 3){
        registration.applyDiscount = true;
        this.discountInRegistration = true;
      }

      if(this.registrations){
        let existingRegistration = this.registrations.filter(x=>x.email.toLowerCase() === registration.email 
                                                                && x.studentName.toLowerCase() === registration.studentName.toLowerCase());

        this.studentAlreadyRegistered = existingRegistration && existingRegistration.length > 0;
      }

      if(!this.studentAlreadyRegistered){
        this.scratchBeginnersCollection.add({...registration})
        .then(()=>{
          this.registrationCompleted = true;
          this.registering = false;
        })
        .catch(()=>{
          this.errorRegistering = true;
          this.registrationCompleted = true;
          this.registering = false;
        });
      }
      else{
        this.registering = false;
      }

    }
  }
}

export class CourseRegistration{
  public id?: string;
  public parentName: string;
  public studentName: string;
  public studentAge: number;
  public email: string;
  public phoneNumber: string;
  public createdOn: Date;
  public applyDiscount: Boolean;
}
