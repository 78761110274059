import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { FooterComponent } from './components/layouts/footer/footer.component';
import { PreloaderComponent } from './components/layouts/preloader/preloader.component';
import { ScratchBeginnersComponent } from './courses/scratch-beginners/scratch-beginners.component';
import { NgbModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { CourseRegistrationComponent } from './courses/course-registration/course-registration.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';

const firebaseConfig = {
  apiKey: "AIzaSyBMADkySuTEMoItdCRWZO5dj789R9rdpFs",
  authDomain: "createchacademy-b7c39.firebaseapp.com",
  projectId: "createchacademy-b7c39",
  storageBucket: "createchacademy-b7c39.appspot.com",
  messagingSenderId: "1097627122064",
  appId: "1:1097627122064:web:411d016c9cdcc30027db15"
};

@NgModule({
  declarations: [
    AppComponent,
    FooterComponent,
    PreloaderComponent,

    ScratchBeginnersComponent,
    CourseRegistrationComponent
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    NgbTooltipModule,
    FormsModule,
    ReactiveFormsModule,
    NgxMaskDirective, NgxMaskPipe,
    AngularFireModule.initializeApp(firebaseConfig),
    AngularFireDatabaseModule
  ],
  providers: [
    provideNgxMask(),
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }